
@font-face {
    font-family: 'heebo';
    src: url('Heebo-ExtraBold.woff2') format('woff2'),
        url('Heebo-ExtraBold.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'heebo';
    src: url('Heebo-Bold.woff2') format('woff2'),
        url('Heebo-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'heebo';
    src: url('Heebo-Medium.woff2') format('woff2'),
        url('Heebo-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'heebo';
    src: url('Heebo-Regular.woff2') format('woff2'),
        url('Heebo-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'heebo';
    src: url('Heebo-Light.woff2') format('woff2'),
        url('Heebo-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'heebo';
    src: url('Heebo-Thin.woff2') format('woff2'),
        url('Heebo-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}
